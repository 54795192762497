



















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import ProductOrCombo from "./partials/ProductOrCombo.vue";
import makeSelectOptions from "@common/util/makeSelectOptions";
import orderStatuses from "@/constants/orderStatuses";

export default Vue.extend({
  components: {
    ProductOrCombo,
  },
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          urlEnabled: true,
          searchBy: "customerPhone",
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            orderId: {
              text: "ID",
              sortable: true,
              width: "100px",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            productOrCombo: {
              text: "Sản phẩm",
            },
            giftedProduct: {
              text: "Quà tặng",
              options: {
                subProp: "giftedProduct.sku",
                label: true,
                sortBy: "giftedProduct.name",
              },
            },
            singlePrice: {
              text: "Đơn giá",
              options: {
                preTransform(_, item) {
                  let value = (item.product && item.product.localPrice) || (item.productCombo && item.productCombo.overriddenLocalPrice) || item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            count: {
              text: "Số lượng",
              sortable: true,
            },
            totalPrice: {
              text: "Tổng giá",
              options: {
                preTransform(_, item) {
                  let value = (item.product && item.product.localPrice) || (item.productCombo && item.productCombo.overriddenLocalPrice) || item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value * item.count;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            customerName: {
              text: "Tên",
              sortable: true,
            },
            customerEmail: {
              text: "Email",
              sortable: true,
            },
            customerPhone: {
              text: "SĐT",
              sortable: true,
            },
            customerMessage: {
              text: "Lời nhắn",
              sortable: true,
            },
            customerState: !userManager.checkRole(["content", "design"], true) && {
              text: "State",
            },
            customerCity: !userManager.checkRole(["content", "design"], true) && {
              text: "City",
            },
            customerDistrict: !userManager.checkRole(["content", "design"], true) && {
              text: "District",
            },
            customerWard: !userManager.checkRole(["content", "design"], true) && {
              text: "Ward",
            },
            customerAddress: !userManager.checkRole(["content", "design"], true) && {
              text: "Address",
            },
            customerPostalCode: !userManager.checkRole(["content", "design"], true) && {
              text: "Postal Code",
            },
            invalidStatus: {
              text: "TT Trùng đơn",
            },
            action: {},
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Mã đơn hàng",
                },
                rules: {
                  equal_to: {},
                },
              },
              "productItems.product._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in_id: {},
                },
              },
              "productCombos._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productItems.product.productGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              customerName: {
                attrs: {
                  label: "Tên KH",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerEmail: {
                attrs: {
                  label: "Email",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerPhone: {
                attrs: {
                  label: "SĐT",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessage: {
                attrs: {
                  label: "Lời nhắn",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessageExist: {
                type: "select",
                attrs: {
                  label: "Có lời nhắn",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "customerMessage",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "customerMessage",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "customerMessage",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "customerMessage",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
              status: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(orderStatuses),
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              check: userManager.checkRole(["kt", "vd", "vdl"]) && {
                content: {
                  icon: "mdi-check",
                },
                on: {
                  async xClick({ self }) {
                    const { dataTable, item } = self.context();
                    await dataTable.execute(
                      async () => {
                        return coreApiClient.call(
                          "orders",
                          "update",
                          {
                            id: item._id,
                          },
                          {
                            data: {
                              invalidStatus: "",
                            },
                            updateRequest: {
                              scenario: "InvalidCheckItemUnsetInvalidStatus",
                            },
                          }
                        );
                      },
                      undefined,
                      {
                        successMessage: `Đơn hàng ${item.orderId} đã chọn thành công`,
                        willRefresh: true,
                      }
                    );
                  },
                },
                target: {
                  tooltip: { content: { text: "Chọn" } },
                },
              },
              cancel: userManager.checkRole(["kt", "vd", "vdl"]) && {
                content: {
                  icon: "mdi-close",
                },
                on: {
                  async xClick({ self }) {
                    const { dataTable, item } = self.context();
                    await dataTable.execute(
                      async () => {
                        return coreApiClient.call(
                          "orders",
                          "update",
                          {
                            id: item._id,
                          },
                          {
                            data: {
                              status: "h",
                              customerRefusedStatus: "dt",
                            },
                            updateRequest: {
                              scenario: "InvalidCheckItemSetStatusH",
                            },
                          }
                        );
                      },
                      undefined,
                      {
                        successMessage: `Đơn hàng ${item.orderId} đã hủy thành công`,
                        willRefresh: true,
                      }
                    );
                  },
                },
                target: {
                  tooltip: { content: { text: "Hủy" } },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            options.filters.push({
              key: "invalid",
              operator: "equal_to",
              value: true,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("orders", "findAll", options);
              return [items, count];
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
